import { createStep } from "../../../helpers/selfHelpBuilder";
import { ArrowIconStep2 } from "./ArrowIconStep2";
import { SearchBoxStep4 } from "./SearchBoxStep4";
import { SectionStep1 } from "./SectionStep1";
import { SplitterStep3 } from "./SplitterStep3";
import { TagTypesListStep5 } from "./TagTypesListStep5";
import { TagTypesListStep6 } from "./TagTypesListStep6";

export const tagTypesSteps = () => {
  return [
    createStep("#tags-navigation", <SectionStep1 />, 520, "right"),
    createStep("#navigation-collapse-button", <ArrowIconStep2 />, 520, "right"),
    createStep("#tags-splitter", <SplitterStep3 />, 520, "right"),
    createStep("#tag-type-search-textfield", <SearchBoxStep4 />, 520, "right"),
    createStep("#tags-type-list", <TagTypesListStep5 />, 520, "right"),
    createStep("#tags-type-list", <TagTypesListStep6 />, 620, "right"),
  ];
};
