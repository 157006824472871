import {
  SelfHelpPage,
  SelfHelpSectionIdentifier,
} from "./selfHelp/selfHelpSection";

export enum CurrentSelfHelp {
  None,
  Navigation,
  Filters,
  Workflow,
  PublicationDate,
  SecurityLevel,
  DocumentListMenu,
  Documents,
  DetailsIdentity,
  DetailsTitles,
  DetailsDescription,
  DetailsProducts,
  DetailsAttachment,
  DetailsSecurity,
  DetailsCountryMarketAvailability,
  DetailsTags,
  DetailsCategories,
  DetailsAddCategories,
  DetailsContextMenuPublish,
  DetailsContextMenuSection,
  CmsMenu,
  CmsNavigation,
  CmsAccessGroups,
  CmsDocumentKinds,
  CmsPermissions,
  CmsConfigurations,
  PublishersMenu,
  PublishersPublisher,
  PublishersNavigation,
  PublishersPublisherList,
  TagsNavBar,
  TagsTypes,
  TagsOwners,
  TagsCategories,
  TagsValues,
}

export const getSectionNameBySelfHelpName = (
  selfHelp: CurrentSelfHelp
): SelfHelpSectionIdentifier | undefined => {
  const name = CurrentSelfHelp[selfHelp];

  if (selfHelp === CurrentSelfHelp.None) {
    return;
  }

  if (name.startsWith("Cms")) {
    return {
      sectionName: name,
      page: SelfHelpPage.Cms,
    };
  }

  if (name.startsWith("Publishers")) {
    return {
      sectionName: name,
      page: SelfHelpPage.Publishers,
    };
  }

  if (name.startsWith("Details")) {
    return {
      sectionName: name.replace("Details", ""),
      page: SelfHelpPage.Details,
    };
  }

  if (name.startsWith("Tags")) {
    return {
      sectionName: name,
      page: SelfHelpPage.Tags,
    };
  }

  return { sectionName: name.replace("Details", ""), page: SelfHelpPage.List };
};
