import { ReactNode } from "react";
import { PaperHeaderDiv } from "./SC/PaperHeaderDiv";
import { StyledPaper } from "./SC/StyledPaper";
import { PaperContentDiv } from "./SC/PaperContentDiv";
import LabelIcon from "../../components/details/inputs/LabelIcon";
import { TooltipMsg } from "../Tooltips/TooltipMessages";
import { SectionName } from "../../app/slices/models/documentDetailsModels";
import { CurrentSelfHelp } from "../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../models/selfHelp/selfHelpSection";
import { SelfHelpButtonControl } from "../Buttons/SelfHelpButtonControl";
import {
  getSectionHeaderColor,
  getValidationStatusText,
} from "../../app/helpers/validationHelper";
import { SectionValidationStatusText } from "../../components/details/sections/SectionHeader/SectionHeaderStatus";
import { PaperHeaderSpan } from "./SC/PaperHeaderSpan";

interface PaperControlProps {
  children: ReactNode;
  title: string;
  count?: number;
  useHeader: boolean;
  disabled?: boolean;
  selfHelpPage: SelfHelpPage;
  selfHelpSectionName: SectionName;
  currentSelfHelp: CurrentSelfHelp;
  isEdited?: boolean;
  id?: string;
}

export function PaperControl(props: PaperControlProps) {
  return (
    <StyledPaper elevation={2} id={props.id}>
      {props.useHeader && (
        <PaperHeaderDiv id={props.id + "-header"} disabled={props.disabled}>
          <PaperHeaderSpan>
            {props.title}
            {props.disabled && (
              <LabelIcon
                marginLeft={6}
                size="large"
                message={TooltipMsg.NoAccessToCategory}
              />
            )}
            <SectionValidationStatusText
              color={getSectionHeaderColor(false, props.isEdited, false)}
            >
              {getValidationStatusText(false, props.isEdited, false)}
            </SectionValidationStatusText>
          </PaperHeaderSpan>

          <SelfHelpButtonControl
            currentSelfHelp={props.currentSelfHelp}
            page={props.selfHelpPage}
            section={props.selfHelpSectionName}
          />
        </PaperHeaderDiv>
      )}
      <PaperContentDiv>{props.children}</PaperContentDiv>
    </StyledPaper>
  );
}
