import { useTheme } from "styled-components";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function TagTypesListStep5() {
    const theme = useTheme();
    return (
        <StepContentDiv>
            <Paragraph>
                Tags can exist in two states:
                <ul>
                    <li><span style={{ color: theme.palettes.grayscale.medium }}> Gray, with a normal font</span>:
                        Tags to which the current user does not have Owner rights.
                    </li>
                    <li><b>Black, with a bolded font</b>:  Tags that the current user is the Owner of.</li>
                </ul>
            </Paragraph>
        </StepContentDiv>
    );
}
