import { Divider } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";
import { setTagsViewInLocalStorage } from "../../app/hooks/localStorage";
import useWindowDimensions from "../../app/hooks/useWindowDimensions";
import { selectIsWindowTooSmall } from "../../app/slices/commonSlice";
import { navigationMinWidth } from "../../app/slices/models/contentModels";
import {
  collapseAllTagCategoryBreadcrumbs,
  selectDashboardFlex,
  selectIsNavigationMinimized,
  selectNavigationFlex,
  selectSelectedTag,
  setDashboardFlex,
  setIsNavigationMinimized,
  setNavigationFlex,
  setSelectedTag,
  setTagsSearch,
} from "../../app/slices/tagsViewSlice";
import {
  HandlerProps,
  ReflexContainer,
  ReflexElement,
  ReflexSplitter,
} from "../../controls/Reflex";
import { ToggleNavigation } from "../documentsNavigation/ToggleNavigation";
import { Footer } from "../footer/Footer";
import { TagsHeader } from "./Header/TagsHeader";
import { NoTagSelected } from "./NoTagSelected/NoTagSelected";
import { DashboardDiv } from "./SC/DashboardDiv";
import { TagsContent } from "./SC/TagsContentDiv";
import { TagsDiv } from "./SC/TagsDiv";
import { TagTypes } from "./TagTypes/TagTypes";
import { TagsDashboard } from "./TagsDashboard/TagsDashboard";

const tagsMinWidth = 680;
const hiddenTagsWidth = 0;
const tagsMinimizedWidth = 20;

const clamp = (value: number) => +Math.max(0, Math.min(1, value)).toFixed(2);

export function Tags() {
  const navigationFlex = useAppSelector(selectNavigationFlex);
  const dashboardFlex = useAppSelector(selectDashboardFlex);
  const isMinimizedByUser = useAppSelector(selectIsNavigationMinimized);
  const isWindowTooSmall = useAppSelector(selectIsWindowTooSmall);
  const { windowWidth } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const selectedTag = useAppSelector(selectSelectedTag);

  const onChangeSizeClick = () => {
    dispatch(setIsNavigationMinimized(!isMinimizedByUser));
  };

  useEffect(() => {
    dispatch(collapseAllTagCategoryBreadcrumbs());
    return () => {
      dispatch(setSelectedTag(undefined));
    };
  }, [dispatch]);

  const maxNavigationWidth = useMemo(
    () => windowWidth - tagsMinWidth,
    [windowWidth]
  );
  const isMinimized = useMemo(
    () => isMinimizedByUser || isWindowTooSmall,
    [isMinimizedByUser, isWindowTooSmall]
  );

  const onNavigationStopResize = useCallback(
    (args: HandlerProps) => {
      if (!args.component.props.flex) {
        return;
      }

      const el = args.domElement as Element;
      const navFlex = clamp(
        el.clientWidth > navigationMinWidth
          ? args.component.props.flex
          : navigationMinWidth / windowWidth
      );
      const pubFlex = 1 - navFlex;

      dispatch(setNavigationFlex(navFlex));
      dispatch(setDashboardFlex(pubFlex));
    },
    [windowWidth, dispatch]
  );

  useEffect(() => {
    const dashboardFlex =
      (windowWidth -
        (isMinimized ? tagsMinimizedWidth : navigationFlex * windowWidth)) /
      windowWidth;
    let clampedPubFlex = clamp(dashboardFlex);
    let navFlex = 1 - clampedPubFlex;

    if (!isMinimized) {
      const navWidth = navFlex * windowWidth;

      if (navWidth < navigationMinWidth) {
        navFlex = Math.max(navFlex, clamp(navigationMinWidth / windowWidth));
        clampedPubFlex = clamp(1 - navFlex);
      }
    }

    dispatch(setDashboardFlex(clampedPubFlex));
    dispatch(setNavigationFlex(navFlex));
  }, [navigationFlex, windowWidth, isMinimized, dispatch]);

  useEffect(() => {
    setTagsViewInLocalStorage({
      navigationFlex: navigationFlex,
      dashboardFlex: dashboardFlex,
      isMinimized: isMinimized,
    });
  }, [navigationFlex, dashboardFlex, isMinimized]);

  useEffect(() => {
    return () => {
      dispatch(setTagsSearch(""))
    }
  }, [dispatch])

  return (
    <DashboardDiv>
      <TagsDiv id="tags-div">
        <ReflexContainer orientation="vertical" windowResizeAware={true}>
          <ReflexElement
            flex={isMinimized ? hiddenTagsWidth : navigationFlex}
            onStopResize={onNavigationStopResize}
            className="left-pane"
            minSize={isMinimized ? tagsMinimizedWidth : navigationMinWidth}
            maxSize={isMinimized ? tagsMinimizedWidth : maxNavigationWidth}
          >
            <TagTypes isMinimized={isMinimized} />
            <ToggleNavigation
              onToggleClick={onChangeSizeClick}
              isMinimized={isMinimized}
            />
          </ReflexElement>
          <ReflexSplitter id="tags-splitter" />
          <ReflexElement
            flex={dashboardFlex}
            minSize={tagsMinWidth}
            className="center-pane"
          >
            <TagsContent>
              <TagsHeader />
              <Divider variant="fullWidth" sx={{ width: "100%" }} />
              {!selectedTag && (
                <NoTagSelected
                  headerMessage="Select Tag"
                  contentMessage="Please select Tag in the left menu to display its settings"
                  headerId="tags-no-tag-selected"
                />
              )}
              {selectedTag && <TagsDashboard />}
            </TagsContent>
          </ReflexElement>
        </ReflexContainer>
      </TagsDiv>
      <Footer />
    </DashboardDiv>
  );
}
