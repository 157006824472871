import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { SelfHelpIcon } from "../../publishers/SelfHelpIcon";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";


export function SearchBoxStep4() {
    return (
        <StepContentDiv>
            <Paragraph>
                The Tag can be searched by name or its part. The Tag list will be narrowed down to Tags that contain the typed-in text.
            </Paragraph>
            <Paragraph>
                New results can be loaded by pressing Enter or using the <SelfHelpIcon icon={<SearchIcon />} />icon. The input can be cleared using the <SelfHelpIcon icon={<CloseIcon />} />icon.
            </Paragraph>
        </StepContentDiv>
    );
}
