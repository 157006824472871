import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep1() {
    return (
        <StepContentDiv>
            <Paragraph>
                This tool allows Tag Owners to add/remove available Tag Values of Tags they manage. Regular users have read access to these settings.
            </Paragraph>
        </StepContentDiv>
    );
}
