
import { StepContentDiv } from "../../../SC/StepContentDiv";
import CheckIcon from "@mui/icons-material/Check";
import { SelfHelpIcon } from "../SelfHelpIcon";
import { Paragraph } from "../../../SC/Paragraph";

export function ListStep1() {
    return (
        <StepContentDiv>
            <Paragraph>
                In this section, all of the user&apos;s directly assigned roles in category are displayed. Role which is assigned in the specified category is marked by <SelfHelpIcon color="black" icon={<CheckIcon />} /> icon.
            </ Paragraph>
        </StepContentDiv>
    );
}
