import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep1() {
    return (
        <StepContentDiv>
            <Paragraph>
                The tag to be viewed needs to be selected first. Then, the additional buttons and tag details will be displayed in the main view.
            </Paragraph>
        </StepContentDiv>
    );
}
