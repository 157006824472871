import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { SelfHelpIcon } from "../../publishers/SelfHelpIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import TagIcon from '@mui/icons-material/Tag';

export function DeleteTagStep4() {
    return (
        <StepContentDiv>
            <Paragraph>
                A Tag Value can be removed from the Tag Value list by the Tag Owner by clicking on <SelfHelpIcon icon={<DeleteIcon />} /> icon.
            </Paragraph>
            <Paragraph>
                Removed Tag Value is marked with red hash avatar <SelfHelpIcon color="red" icon={<TagIcon />} />and goes to the top of the list where it can be restored using <SelfHelpIcon icon={<RestoreFromTrashIcon />} />.
            </Paragraph>
            <Paragraph>
                If a Tag Value was used in documents, it will be removed from those documents when changes are saved.
            </Paragraph>
        </StepContentDiv>
    );
}