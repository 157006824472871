import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep1() {
    return (
        <StepContentDiv>
            <Paragraph>
                A list of Tag Owners is displayed in this section.
            </Paragraph>
            <Paragraph>
                Tag Owners can be contacted with requests for modifying Tag Values list.
            </Paragraph>
        </StepContentDiv>
    );
}
