import { createStep } from "../../../helpers/selfHelpBuilder";
import { CloseButtonStep4 } from "./CloseButtonStep4";
import { DiscardChangesStep3 } from "./DiscardChangesStep3";
import { SaveChangesStep2 } from "./SaveChangesStep2";
import { SectionStep1 } from "./SectionStep1";

export const navBarSteps = () => {
  return [
    createStep("#tags-action-buttons", <SectionStep1 />, 520, "left"),
    createStep("#tags-save-button", <SaveChangesStep2 />, 620, "left"),
    createStep("#tags-discard-changes-button", <DiscardChangesStep3 />, 620, "left"),
    createStep("#tags-close-button", <CloseButtonStep4 />, 620, "left")
  ];
};
