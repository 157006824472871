import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { SelfHelpIcon } from "../SelfHelpIcon";

export function ColumnButtonStep1() {
    return (
        <StepContentDiv>
            <Paragraph>
                The <SelfHelpIcon color="black" icon={<ViewColumnIcon />} /> Columns button is used to apply changes to the Publishers list view. It provides options to show or hide chosen columns.
            </Paragraph>
        </StepContentDiv>
    );
}