import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import CheckIcon from "@mui/icons-material/Check";
import { SelfHelpIcon } from "../SelfHelpIcon";

export function PublisherListStep10() {
    return (
        <StepContentDiv>
            <Paragraph>
                Role can be granted on the selected category or inherited from any category above the selected category.
            </Paragraph>
            <Paragraph>
                If the role is inherited, then the category name on which the role is granted is displayed instead of the <SelfHelpIcon color="black" icon={<CheckIcon />} /> icon.
            </Paragraph>
        </StepContentDiv>
    );
}
