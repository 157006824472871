import { ConfirmationDialogControl } from "../../../../controls/Dialog/ConfirmationDialogControl";
import { StyledDialogContentText } from "../../TagValues/SC/StyledDialogContentText";



interface TagValuesWithDocumentsConfirmDialogProps {
  id: string;
  onCloseClick: (e: React.MouseEvent) => void;
  onYesClick: (e: React.MouseEvent) => void;
  onNoClick: (e: React.MouseEvent) => void;
  isOpen: boolean;
}

export function TagValuesWithDocumentsConfirmDialog(
  props: TagValuesWithDocumentsConfirmDialogProps
) {
  return (
    <ConfirmationDialogControl
      id={props.id}
      title="Do you wish to delete tag values that are assigned to documents?"
      onYesClick={props.onYesClick}
      onNoClick={props.onNoClick}
      onCloseClick={props.onCloseClick}
      maxWidth="sm"
      isOpen={props.isOpen}
      content={
        <StyledDialogContentText id="alert-dialog-description">
          You are about to delete selected tag values.
          Some of selected values are currently assigned to one or more documents.
          By confirming, you acknowledge that these tags will also be permanently removed from any associated documents.
        </StyledDialogContentText>
      }
    />
  );
}
