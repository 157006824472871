import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { SelfHelpIcon } from "../../publishers/SelfHelpIcon";

export function ArrowIconStep2() {
    return (
        <StepContentDiv>
            <Paragraph>
                The entire menu can be collapsed by using the <SelfHelpIcon icon={<KeyboardDoubleArrowLeftIcon />} /> icon.
            </Paragraph>
        </StepContentDiv>
    );
}
