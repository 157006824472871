import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SaveChangesStep2() {
    return (
        <StepContentDiv>
            <Paragraph>
                Changes made to a selected Tag can be saved by using this action. If any changes are made, they are highlighted in color. The button remain disabled until a Tag is selected.
            </Paragraph>
        </StepContentDiv>
    );
}
