import React from "react";
import { useTheme } from "styled-components";

interface SelfHelpIconProps {
    icon: React.ReactNode;
    color?: "black" | "gray" | "blue" | "red";
}



export function SelfHelpIcon(props: SelfHelpIconProps) {
    const theme = useTheme();
    const getColor = (color?: "black" | "gray" | "blue" | "red",) => {
        switch (color) {
            case "black": return theme.palettes.grayscale.darkest;
            case "gray": return theme.palettes.grayscale.dark;
            case "blue": return theme.palettes.secondary.info;
            case "red": return theme.palettes.primary.main;
            default: return "black";
        }
    }

    return (
        <div style={{
            display: "inline-block",
            fontSize: "16px",
            verticalAlign: "middle",
            color: getColor(props.color),
        }}>{props.icon}</div>

    );
}
