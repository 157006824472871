import { useEffect, useMemo, useState } from "react";
import { useGetUserTagsListQuery } from "../../../apis/tagsApi";
import {
  ErrorContainerProps,
  ErrorDisplay,
} from "../../../app/errorHandling/ErrorDisplay";
import { openSnackbar } from "../../../app/helpers/snackBarHelper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import {
  selectTagsSearch,
  setSelectedTag,
} from "../../../app/slices/tagsViewSlice";
import { SnackbarType } from "../../../models/snackbar";
import TagsListItem from "../../../models/tags/tagsListItem";
import { TagTypesDiv } from "./SC/TagTypesDiv";
import { TypesDiv } from "./SC/TypesDiv";
import { TagType } from "./TagType/TagType";
import { TagTypeSkeletons } from "./TagType/TagTypeSkeletons";
import { TagTypeSearch } from "./TagTypeSearch/TagTypeSearch";
import TagTypesHeader from "./TagTypesHeader";
import {
  clearTagValues,
  selectTagsIsEdited,
} from "../../../app/slices/tagsSlice";
import { WarningPopup } from "../../cms/Config/popups/WarningPopup";

const errorContainerStyles: ErrorContainerProps = {
  position: "absolute",
  transform: "translate(-50%, -50%)",
  top: "50%",
  left: "50%",
};

interface TagTypesProps {
  isMinimized: boolean;
}

export function TagTypes(props: TagTypesProps) {
  const dispatch = useAppDispatch();
  const tagsSearch = useAppSelector(selectTagsSearch);
  const tagsIsEdited = useAppSelector(selectTagsIsEdited);
  const [sortedTags, setSortedTags] = useState<TagsListItem[]>([]);
  const [filteredTags, setFilteredTags] = useState<TagsListItem[]>([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [nextSelectedTag, setNextSelectedTag] = useState<TagsListItem | null>();

  const {
    data: tags,
    isFetching,
    isLoading,
    isUninitialized,
    isError,
    refetch,
  } = useGetUserTagsListQuery(null);

  const showLoading = useMemo(
    () => isLoading || isFetching || isUninitialized,
    [isLoading, isFetching, isUninitialized]
  );

  const onClickHandler = (tag: TagsListItem) => {
    if (tagsIsEdited) {
      setPopupOpen(true);
      setNextSelectedTag(tag);
    } else {
      dispatch(setSelectedTag(tag));
      dispatch(clearTagValues());
    }
  };

  const confirmWarningDialog = () => {
    if (nextSelectedTag) {
      dispatch(setSelectedTag(nextSelectedTag));
      dispatch(clearTagValues());
      setNextSelectedTag(null);
      setPopupOpen(false);
    }
  };

  useEffect(() => {
    if (isError) {
      openSnackbar(
        dispatch,
        "Error while loading tags list",
        SnackbarType.error
      );
    }
  }, [isError, dispatch]);

  useEffect(() => {
    if (tags) {
      const sorted = tags
        .map((x) => x)
        .sort((a: TagsListItem, b: TagsListItem) => {
          if (a.canEdit && !b.canEdit) {
            return -1;
          }

          if (!a.canEdit && b.canEdit) {
            return 1;
          }

          return a.displayName.localeCompare(b.displayName, undefined, {
            sensitivity: "base",
          });
        });
      setSortedTags(sorted);
      setFilteredTags(sorted);
    }
  }, [tags]);

  useEffect(() => {
    const loweredSearch = tagsSearch.toLocaleLowerCase().trim();
    const filtered = sortedTags.filter((x) =>
      x.displayName.toLocaleLowerCase().includes(loweredSearch)
    );
    setFilteredTags(filtered);
  }, [sortedTags, tagsSearch]);

  return (
    <TagTypesDiv id="tags-navigation" $isMinimized={props.isMinimized}>
      <TagTypesHeader />
      <TagTypeSearch />
      {!isError ? (
        <TypesDiv id="tags-type-list">
          {showLoading ? (
            <TagTypeSkeletons numberOfSkeletons={10} />
          ) : (
            filteredTags.map((tag) => (
              <TagType
                id={tag.id}
                key={tag.id}
                type={tag.type}
                primaryInfo={tag.displayName}
                hasAccess={tag.canEdit}
                onClick={() => {
                  onClickHandler(tag);
                }}
              />
            ))
          )}
        </TypesDiv>
      ) : (
        <ErrorDisplay
          errorId={"tags-list"}
          errorContainerProps={errorContainerStyles}
          errorMessageTitle={"Something went wrong"}
          errorDescription={"Tags loading failed"}
          showDash={false}
          showReloadButton={true}
          textAlign="center"
          refreshFunction={() => {
            void refetch();
          }}
        />
      )}
      <WarningPopup
        title="Tag has unsaved changes"
        message="All changes will be lost. Are you sure you want to change Tag?"
        isOpen={popupOpen}
        onConfirm={confirmWarningDialog}
        onClose={() => setPopupOpen(false)}
      />
    </TagTypesDiv>
  );
}
