import { Paragraph } from "../../../SC/Paragraph";
import { Screenshot } from "../../../SC/Screenshot";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import screenshot from "../../../screenshots/tags/addnewvalue.png"
import { SelfHelpIcon } from "../../publishers/SelfHelpIcon";
import TagIcon from '@mui/icons-material/Tag';

export function AddNewTagStep3b() {
    return (
        <StepContentDiv>
            <Paragraph>
                This field is used to add a new Tag Value to the Tag Values list.
                <Screenshot src={screenshot} />
            </Paragraph>
            <Paragraph>
                This field is only available to a Tag Owner.
            </Paragraph>
            <Paragraph>
                Value that is added can later be used when publishing a document.
            </Paragraph>
            <Paragraph>
                New Tag Value is visible with blue hash avatar <SelfHelpIcon color="blue" icon={<TagIcon />} />  at the top of the list.
            </Paragraph>
        </StepContentDiv>
    );
}
