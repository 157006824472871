import { useTheme } from "styled-components";
import { TextButtonControl } from "../../../../controls/Buttons/TextButtonControl";
import SaveIcon from "@mui/icons-material/Save";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import TagsListItem from "../../../../models/tags/tagsListItem";
import { useState } from "react";
import { TagValuesWithDocumentsConfirmDialog } from "./TagValuesWithDocumentsConfirmDialog";
import { useAppSelector } from "../../../../app/hooks/hooks";
import { selectRemovedTagsWithDocuments } from "../../../../app/slices/tagsSlice";

interface SaveChangesButtonProps {
  isEdited?: boolean;
  canEdit?: boolean;
  isLoading: boolean;
  hasErrors: boolean;
  selectedTag?: TagsListItem;
  onSaveTagValues: () => void;
}

export function SaveChangesButton(props: SaveChangesButtonProps) {
  const theme = useTheme();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const removedTagValuesWithDocuments = useAppSelector(selectRemovedTagsWithDocuments);

  const disableTooltipText = () => {
    switch (true) {
      case !props.selectedTag:
        return TooltipMsg.ChooseTagFirst;
      case props.hasErrors:
        return TooltipMsg.IsSomeValidationErrors;
      case !props.canEdit:
        return TooltipMsg.NoAccessToTag;
      case !props.isEdited:
        return TooltipMsg.NoChangesMade;
      default:
        return "";
    }
  };

  const handleSaveChangesClick = () => {
    if (removedTagValuesWithDocuments.length > 0) {
      setIsConfirmDialogOpen(true);
    } else {
      props.onSaveTagValues();
    }
  }

  const onConfirmDialogYesClick = () => {
    setIsConfirmDialogOpen(false);
    props.onSaveTagValues();
  }

  const onConfirmDialogNoOrCloseClick = () => {
    setIsConfirmDialogOpen(false);
  }

  return (
    <>
      <TextButtonControl
        id={"tags-save-button"}
        colors={"primary"}
        height={theme.shapes.primaryButtonHeight}
        text={"Save changes"}
        onClick={handleSaveChangesClick}
        isCompactView={false}
        isVertical={false}
        isVisible={true}
        disabled={
          !props.isEdited || !props.canEdit || props.isLoading || props.hasErrors
        }
        disabledTooltipText={disableTooltipText()}
        icon={<SaveIcon fontSize="small" />}
      />
      <TagValuesWithDocumentsConfirmDialog
        id={"tag-values-delete-confirm-dialog"}
        isOpen={isConfirmDialogOpen}
        onYesClick={onConfirmDialogYesClick}
        onNoClick={onConfirmDialogNoOrCloseClick}
        onCloseClick={onConfirmDialogNoOrCloseClick}
      />
    </>
  );
}
