import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";


export function SectionStep1() {
    return (
        <StepContentDiv>
            <Paragraph>
                The Tag Values section is used to display Tag Values of the selected Tag.
            </Paragraph>
            <Paragraph>
                The number in brackets represents the number of documents that are using given Tag Value.
            </Paragraph>
        </StepContentDiv>
    );
}
