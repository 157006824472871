import { Step } from "react-joyride";
import { createStep } from "../../../helpers/selfHelpBuilder";
import { AddNewTagStep3a } from "./AddNewTagStep3a";
import { AddNewTagStep3b } from "./AddNewTagStep3b";
import { DeleteTagStep4 } from "./DeleteTagStep4";
import { SearchBoxStep2 } from "./SearchBoxStep2";
import { SectionStep1 } from "./SectionStep1";

export const tagsValuesSteps = (canEditSelectedTag?: boolean, isAnyTagRemoveIcon?: boolean) => {
  const steps: Step[] = [];

  steps.push(createStep("#tags-values-paper-control-header", <SectionStep1 />, 320, "bottom"));
  steps.push(createStep("#tag-value-search-input-div", <SearchBoxStep2 />, 480, "bottom"));

  if (canEditSelectedTag) {
    steps.push(createStep("#add-tag-input-div", <AddNewTagStep3a />, 460, "bottom"));
  } else {
    steps.push(createStep("#tags-values-paper-control-header", <AddNewTagStep3b />, 460, "bottom"));
  }

  if (isAnyTagRemoveIcon) {
    steps.push(createStep(".remove-tag-value-icon", <DeleteTagStep4 />, 480, "left"));
  } else {
    steps.push(createStep("#tags-values-paper-control-header", <DeleteTagStep4 />, 480, "bottom"));
  }

  return steps;

};
