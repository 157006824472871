import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import { useAppSelector } from "../../../../app/hooks/hooks";
import { selectSelectedTag } from "../../../../app/slices/tagsViewSlice";
import { TooltipTop } from "../../../../controls/Tooltips/ToolipTop";
import { TooltipMsg } from "../../../../controls/Tooltips/TooltipMessages";
import { ItemAvatarDiv } from "./SC/ItemAvatarDiv";
import { StyledAvatar } from "./SC/StyledAvatar";
import { StyledListItem } from "./SC/StyledListItem";
import { StyledListItemAvatar } from "./SC/StyledListItemAvatar";
import { StyledListItemText } from "./SC/StyledListItemText";
import { TagTypeEnum } from '../../../../models/tags/tagsListItem';
import { CustomBookmarkSpecialIcon } from './CustomBookmarkSpecialIcon';

export interface TagTypeProps {
  id: string;
  type: TagTypeEnum;
  primaryInfo: string;
  secondaryInfo?: string;
  hasAccess: boolean;
  onClick: () => void;
}

export function TagType(props: TagTypeProps) {
  const selectedTag = useAppSelector(selectSelectedTag);

  return (
    <TooltipTop
      title={
        props.hasAccess
          ? TooltipMsg.UserIsTagOwner
          : TooltipMsg.UserIsNotTagOwner
      }
    >
      <ItemAvatarDiv
        id={`tag-${props.id}`}
        onClick={props.onClick}
        $isSelected={selectedTag?.id === props.id}>
        <StyledListItem>
          <StyledListItemAvatar>
            <StyledAvatar>
              {props.type === TagTypeEnum.SingleValueTag && <BookmarkBorderOutlinedIcon style={{ fontSize: "16px" }} />}
              {props.type === TagTypeEnum.MultiValueTag && <BookmarksOutlinedIcon style={{ fontSize: "16px" }} />}
              {props.type === TagTypeEnum.SpecialTag && <CustomBookmarkSpecialIcon fontSize={"16px"} fill="white" />}
            </StyledAvatar>
          </StyledListItemAvatar>
          <StyledListItemText
            $hasAccess={props.hasAccess}
            primary={props.primaryInfo}
            secondary={props.secondaryInfo} />
        </StyledListItem>
      </ItemAvatarDiv>
    </TooltipTop>
  );
}
