import { useAppSelector } from "../../../app/hooks/hooks";
import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import { selectTagsIsEdited } from "../../../app/slices/tagsSlice";
import {
  selectCanEditSelectedTag,
  selectSelectedTag,
} from "../../../app/slices/tagsViewSlice";
import { SelfHelpButtonControl } from "../../../controls/Buttons/SelfHelpButtonControl";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import { CloseButton } from "../buttons/Close/CloseButton";
import { DiscardChangesButton } from "../buttons/DiscardChanges/DiscardChangesButton";
import { SaveChangesButton } from "../buttons/SaveChanges/SaveChangesButton";
import { HeaderEndAdornmentDiv } from "../SC/HeaderEndAdornmentDiv";
import { TagInfo } from "../TagValues/TagInfo";
import { HeaderDiv } from "./SC/HeaderDiv";
import { MainHeaderDiv } from "./SC/MainHeaderDiv";
import { SelfHelpButtonControlSpan } from "./SC/SelfHelpButtonControlSpan";
import { useTagChanges } from "../../../app/hooks/useTagChanges";
import { LinearProgressDiv } from "../../../controls/ProgressIndicators/SC/LinearProgressDiv";
import { LinearProgressBar } from "../../../controls/ProgressIndicators/LinearProgressBar";

export function TagsHeader() {
  const canEditSelectedTag = useAppSelector(selectCanEditSelectedTag);
  const tagsIsEdited = useAppSelector(selectTagsIsEdited);
  const selectedTag = useAppSelector(selectSelectedTag);
  const { onSaveHandler, isLoading } = useTagChanges();

  return (
    <>
      <HeaderDiv id="header-div">
        <MainHeaderDiv disabled={false}>
          <TagInfo />
        </MainHeaderDiv>
        <HeaderEndAdornmentDiv id="tags-action-buttons">
          <SaveChangesButton
            onSaveTagValues={onSaveHandler}
            canEdit={canEditSelectedTag}
            isEdited={tagsIsEdited}
            isLoading={false}
            hasErrors={false}
            selectedTag={selectedTag}
          />
          <DiscardChangesButton
            hasErrors={false}
            isLoading={false}
            canEdit={canEditSelectedTag}
            isEdited={tagsIsEdited}
            selectedTag={selectedTag}
          />
          <CloseButton />
          <SelfHelpButtonControlSpan>
            <SelfHelpButtonControl
              currentSelfHelp={CurrentSelfHelp.TagsNavBar}
              page={SelfHelpPage.Tags}
              section={SectionName.TagsNavBar}
            />
          </SelfHelpButtonControlSpan>
        </HeaderEndAdornmentDiv>
      </HeaderDiv>
      <LinearProgressDiv
        data-active={isLoading}
        $isVisible={isLoading}
        id="context-menu-settings-linear-progress-div"
      >
        <LinearProgressBar />
      </LinearProgressDiv>
    </>
  );
}
