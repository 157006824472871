import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { SelfHelpIcon } from "../SelfHelpIcon";

export function DensityButtonStep5() {
    return (
        <StepContentDiv>
            <Paragraph>
                The <SelfHelpIcon color="black" icon={<TableRowsIcon />} /> Density button adjusts the spacing between entries in the document list. The default setting is “Standard”.
            </Paragraph>
        </StepContentDiv>
    );
}
