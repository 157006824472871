import { Placement, Step } from "react-joyride";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { documentMenuListSteps } from "../steps/dashboard/documentMenuListSteps";
import { filtersSteps } from "../steps/dashboard/filtersSteps";
import { navigationSteps } from "../steps/dashboard/navigationSteps";
import { publicationDateSteps } from "../steps/dashboard/publicationDateSteps";
import { securityLevelSteps } from "../steps/dashboard/securityLevelSteps";
import { workflowSteps } from "../steps/dashboard/workflowSteps";
import { productsSteps } from "../steps/details/productsSteps";
import { descriptionSteps } from "../steps/details/descriptionSteps";
import { attachmentSteps } from "../steps/details/attachmentSteps";
import { cmaSteps } from "../steps/details/cmaSteps";
import { tagsSteps } from "../steps/details/tagsSteps";
import { securitySteps } from "../steps/details/securitySteps";
import { categoriesSteps } from "../steps/details/categoriesSteps";
import { identitySteps } from "../steps/details/identitySteps";
import { titleSteps } from "../steps/details/titlesSteps";
import { documentsSteps } from "../steps/dashboard/documentsSteps";
import { DocumentsColumnType } from "../../../models/documentList/types/DocumentColumnType";
import { find } from "lodash";
import { addCategoriesSteps } from "../steps/details/addCategoriesSteps";
import { ActionButton } from "../../../app/slices/selfHelpSlice";
import { cmsMenuSteps } from "../steps/cms/Menu/cmsMenuSteps";
import { accessGroupsSteps } from "../steps/cms/AccessGroups/accessGroupsSteps";
import { configurationsSteps } from "../steps/cms/Configurations/configurationsSteps";
import { documentKindsSteps } from "../steps/cms/DocumentKinds/documentKindsSteps";
import { cmsNavigationSteps } from "../steps/cms/Navigation/cmsNavigationSteps";
import { permissionsSteps } from "../steps/cms/Permissions/permissionsSteps";
import { publishersMenuSteps } from "../steps/publishers/Menu/publishersMenuSteps";
import { publishersNavigationSteps } from "../steps/publishers/Navigation/publishersNavigationSteps";
import { publisherSteps } from "../steps/publishers/Publisher/publisherSteps";
import { publisherListSteps } from "../steps/publishers/PublisherList/publisherListSteps";
import { contextMenuPublishSteps } from "../steps/details/contextMenuPublishSteps";
import { contextMenuSectionSteps } from "../steps/details/contextMenuSectionSteps";
import { tagOwnersSteps } from "../steps/tags/owner/tagOwnersSteps";
import { tagCategoriesSteps } from "../steps/tags/categories/tagCategoriesSteps";
import { tagTypesSteps } from "../steps/tags/types/tagTypesSteps";
import { navBarSteps } from "../steps/tags/navbar/navBarSteps";
import { tagsValuesSteps } from "../steps/tags/values/tagsValuesSteps";

export const createStep = (
  target: string,
  content: React.ReactNode,
  width: number,
  placement: Placement | "auto" | "center"
): Step => {
  return {
    target: target,
    content: content,
    placement: placement,
    disableOverlayClose: true,
    disableScrolling: true,
    disableBeacon: true,
    styles: {
      tooltip: {
        zIndex: 1400,
        width: width,
      },
      tooltipContent: {
        zIndex: 1400,
        padding: 8,
      },
    },
  };
};

export const isColumnVisible = (
  visibleColumns: DocumentsColumnType[],
  columnType: DocumentsColumnType
) => {
  return find(visibleColumns, (ct: DocumentsColumnType) => {
    return ct === columnType;
  });
};

export const getSelfHelpSteps = (
  selfHelp: CurrentSelfHelp, visibleColumns: DocumentsColumnType[], hasAnyDocumentGroupOnView: boolean, hasAnySingleDocumentOnView: boolean, securityTabAlternativeView: boolean, isAccessGroupsControlVisible: boolean, isAnyCategoryVisible: boolean, isAnyPopularCategoryVisible: boolean, actionButtons: ActionButton[], isEmailExportsButton: boolean, canEditSelectedTag?: boolean, isAnyTagRemoveIcon?: boolean) => {
  switch (selfHelp) {
    case CurrentSelfHelp.Navigation:
      return navigationSteps();
    case CurrentSelfHelp.Filters:
      return filtersSteps();
    case CurrentSelfHelp.Workflow:
      return workflowSteps();
    case CurrentSelfHelp.PublicationDate:
      return publicationDateSteps();
    case CurrentSelfHelp.SecurityLevel:
      return securityLevelSteps();
    case CurrentSelfHelp.DocumentListMenu:
      return documentMenuListSteps();
    case CurrentSelfHelp.Documents:
      return documentsSteps(
        visibleColumns,
        hasAnyDocumentGroupOnView,
        hasAnySingleDocumentOnView
      );
    case CurrentSelfHelp.DetailsIdentity:
      return identitySteps();
    case CurrentSelfHelp.DetailsTitles:
      return titleSteps();
    case CurrentSelfHelp.DetailsDescription:
      return descriptionSteps();
    case CurrentSelfHelp.DetailsProducts:
      return productsSteps();
    case CurrentSelfHelp.DetailsAttachment:
      return attachmentSteps();
    case CurrentSelfHelp.DetailsSecurity:
      return securitySteps(
        securityTabAlternativeView,
        isAccessGroupsControlVisible
      );
    case CurrentSelfHelp.DetailsCountryMarketAvailability:
      return cmaSteps();
    case CurrentSelfHelp.DetailsTags:
      return tagsSteps();
    case CurrentSelfHelp.DetailsCategories:
      return categoriesSteps(isAnyCategoryVisible, isAnyPopularCategoryVisible);
    case CurrentSelfHelp.DetailsAddCategories:
      return addCategoriesSteps();
    case CurrentSelfHelp.DetailsContextMenuPublish:
      return contextMenuPublishSteps(actionButtons);
    case CurrentSelfHelp.DetailsContextMenuSection:
      return contextMenuSectionSteps();
    case CurrentSelfHelp.CmsMenu:
      return cmsMenuSteps();
    case CurrentSelfHelp.CmsAccessGroups:
      return accessGroupsSteps();
    case CurrentSelfHelp.CmsConfigurations:
      return configurationsSteps();
    case CurrentSelfHelp.CmsDocumentKinds:
      return documentKindsSteps();
    case CurrentSelfHelp.CmsNavigation:
      return cmsNavigationSteps();
    case CurrentSelfHelp.CmsPermissions:
      return permissionsSteps();
    case CurrentSelfHelp.PublishersMenu:
      return publishersMenuSteps(isEmailExportsButton);
    case CurrentSelfHelp.PublishersNavigation:
      return publishersNavigationSteps();
    case CurrentSelfHelp.PublishersPublisherList:
      return publisherListSteps();
    case CurrentSelfHelp.PublishersPublisher:
      return publisherSteps();
    case CurrentSelfHelp.TagsOwners:
      return tagOwnersSteps();
    case CurrentSelfHelp.TagsTypes:
      return tagTypesSteps();
    case CurrentSelfHelp.TagsCategories:
      return tagCategoriesSteps();
    case CurrentSelfHelp.TagsNavBar:
      return navBarSteps();
    case CurrentSelfHelp.TagsValues:
      return tagsValuesSteps(canEditSelectedTag, isAnyTagRemoveIcon);
    default:
      throw new Error("Unsupported CurrentSelfHelp value");
  }
};
