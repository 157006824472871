import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { SelfHelpIcon } from "../../publishers/SelfHelpIcon";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

export function SearchBoxStep2() {
    return (
        <StepContentDiv>
            <Paragraph>
                This field is used to find the Tag Value on the list.
            </Paragraph>
            <Paragraph>
                New results can be loaded by pressing Enter or using the <SelfHelpIcon icon={<SearchIcon />} /> icon. The input can be cleared using the <SelfHelpIcon icon={<CloseIcon />} /> icon.
            </Paragraph>
        </StepContentDiv>
    );
}
