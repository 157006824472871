import { includes, sortBy } from "lodash";
import { useState } from "react";
import useDocumentValidationRules from "../../../app/hooks/document/useDocumentValidationRules";
import { useAppDispatch } from "../../../app/hooks/hooks";
import { setEditedProducts } from "../../../app/slices/documentMetadataSlice";
import { ChipsInput } from "../../../controls/ChipsInput/ChipsInput";
import { SectionFieldDiv } from "./SC/SectionFieldDiv";

interface ProductsProps {
  id: string;
  isEditable: boolean;
  notEditableMessage?: string;
  products: string[];
  isEdited: boolean;
  editedItems: string[];
}

export function Products(props: ProductsProps) {
  const [showDeleteIcons, setShowDeleteIcons] = useState(false);
  const dispatch = useAppDispatch();
  const { getProductsValidationRules } = useDocumentValidationRules();

  const handleMouseEnter = () => {
    setShowDeleteIcons(true);
  };

  const handleMouseLeave = () => {
    setShowDeleteIcons(false);
  };

  const productsChangeHandler = (values: string[]) => {
    dispatch(setEditedProducts(values));
  };

  function getDocumentProducts() {
    const documentLanguages = props.products.map((product) => {
      return {
        key: product,
        value: product,
        isEdited: includes(props.editedItems, product),
      };
    });

    return sortBy(documentLanguages, ["isEdited", "value"]);
  }

  return (
    <SectionFieldDiv
      id={props.id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ChipsInput
        pillsClassName={"product-pill"}
        isEditable={props.isEditable}
        placeholder="Type and press Enter to add new Product ID... (optional)"
        showDeleteIcons={showDeleteIcons}
        values={getDocumentProducts()}
        id={props.id}
        isEdited={props.isEdited}
        onChangeHandler={productsChangeHandler}
        validationRules={getProductsValidationRules()}
      />
    </SectionFieldDiv>
  );
}
