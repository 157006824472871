import { StyledListItemAvatar } from "../SC/StyledListItemAvatar";
import TagIcon from "@mui/icons-material/Tag";
import { StyledListItemText } from "../SC/StyledListItemText";
import { StyledValueListItem } from "../SC/StyledValueListItem";
import { StyledAvatar } from "../SC/StyledAvatar";
import { TagValue } from "../../../../models/tags/TagValue";
import { TagValueIconButton } from "./TagValueIconButton";

interface TagValueInfoProps {
  canEdit?: boolean;
  style: React.CSSProperties;
  tagValue: TagValue;
}

export function TagValueInfo(props: TagValueInfoProps) {
  const id = props.tagValue.tagValueId;
  const isAdded = props.tagValue.added;
  const isRemoved = props.tagValue.removed;
  const name = props.tagValue.value;
  const countOfDocuments = props.tagValue.countOfDocuments ?? 0;


  return (
    <StyledValueListItem
      id={`tag-value-${name}`}
      className="tag-value-row"
      key={id}
      style={props.style}
      secondaryAction={
        <TagValueIconButton tagValue={props.tagValue} canEdit={props.canEdit} />
      }
    >
      <StyledListItemAvatar>
        <StyledAvatar added={isAdded} removed={isRemoved}>
          <TagIcon
            fontSize="small"
            style={{
              fontSize: "18px",
            }}
          />
        </StyledAvatar>
      </StyledListItemAvatar>

      <StyledListItemText
        primary={`${name}`}
        primaryTypographyProps={{
          noWrap: true,
          width: "calc(100% - 32px)",
        }}
        secondary={isAdded ? "New value" : `Used in ${countOfDocuments} document` + (countOfDocuments === 1 ? "" : "s")}
        secondaryTypographyProps={{
          fontSize: "10px",
        }}
      />

    </StyledValueListItem>
  );
}
