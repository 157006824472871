
import { CircularProgress } from "@mui/material";
import { differenceBy, filter, includes, orderBy } from "lodash";
import { useState, useEffect } from "react";
import { ListChildComponentProps } from "react-window";
import { useTheme } from "styled-components";
import { ErrorDisplay } from "../../../app/errorHandling/ErrorDisplay";
import { useAppSelector } from "../../../app/hooks/hooks";
import { SectionName } from "../../../app/slices/models/documentDetailsModels";
import { selectCanEditSelectedTag, selectSelectedTag, selectTagsValueSearch } from "../../../app/slices/tagsViewSlice";
import { PaperControl } from "../../../controls/Paper/PaperControl";
import { CurrentSelfHelp } from "../../../models/CurrentSelfHelpType";
import { SelfHelpPage } from "../../../models/selfHelp/selfHelpSection";
import { TagValue } from "../../../models/tags/TagValue";
import { TagValuesDiv } from "../SC/TagValuesDiv";
import { AddTagValue } from "./AddValue/AddTagValue";
import { useTagValues } from "./hooks/useTagValues";
import { NoTagsFound } from "./NoTagsFound";
import { PaperContentCenterDiv } from "./SC/PaperContentCenterDiv";
import { StyledFixedSizeList } from "./SC/StyledFixedSizeList";
import { ValuesList } from "./SC/ValuesList";
import { SearchTagValue } from "./SearchValue/SearchTagValue";
import { TagValueInfo } from "./TagValueInfo/TagValueInfo";
import { selectAddedTags, selectRemovedTags } from "../../../app/slices/tagsSlice";

export function TagValues() {
  const theme = useTheme();
  const { fetchTagValues, isValuesError, isValuesFetching, tagValuesData } =
    useTagValues();
  const [tagValues, setTagValues] = useState<TagValue[]>([]);
  const [count, setCount] = useState<number>(0);

  const canEditSelectedTag = useAppSelector(selectCanEditSelectedTag);
  const selectedTag = useAppSelector(selectSelectedTag);
  const tagValuesSearch = useAppSelector(selectTagsValueSearch);
  const addedTags = useAppSelector(selectAddedTags);
  const removedTags = useAppSelector(selectRemovedTags);

  useEffect(() => {
    if (selectedTag?.id) {
      setCount(0);
    }
  }, [selectedTag?.id]);

  useEffect(() => {
    fetchTagValues();
  }, [fetchTagValues]);

  useEffect(() => {
    if (tagValuesData) {
      setCount(tagValuesData.count);
      setTagValues(tagValuesData.values);
    }
  }, [tagValuesData]);

  function renderRow(props: ListChildComponentProps) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { index, style, data } = props;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const tagValue = data[index] as TagValue;
    return (
      <TagValueInfo
        style={style}
        canEdit={canEditSelectedTag}
        tagValue={tagValue}
      />
    );
  }

  const prepareTags = (values: TagValue[]) => {
    const sorted = orderBy(values, ["value"], ["asc"]);
    if (tagValuesSearch) {
      return filter(sorted, (item) =>
        includes(item.value.toLowerCase(), tagValuesSearch.toLowerCase())
      );
    }

    return sorted;
  };

  const content = () => {
    if (isValuesError) {
      return (
        <PaperContentCenterDiv>
          <ErrorDisplay
            errorId={"tag-values-error-display"}
            errorMessageTitle={"Something went wrong"}
            errorDescription={"Error while fetching tag owners."}
            refreshFunction={fetchTagValues}
            showReloadButton={true}
          />
        </PaperContentCenterDiv>
      );
    }

    if (isValuesFetching) {
      return (
        <PaperContentCenterDiv>
          <CircularProgress
            sx={{ color: theme.palettes.primary.main }}
            size="30px"
          />
        </PaperContentCenterDiv>
      );
    }

    if (!selectedTag) {
      <PaperContentCenterDiv>
        <CircularProgress
          sx={{ color: theme.palettes.primary.main }}
          size="30px"
        />
      </PaperContentCenterDiv>;
    }

    const addedTagValues = prepareTags(addedTags);
    const removedTagValues = prepareTags(removedTags);

    if (
      tagValues.length > 0 ||
      addedTagValues.length > 0 ||
      removedTagValues.length > 0
    ) {
      const tagsWithoutRemoved = differenceBy(
        tagValues,
        removedTags,
        "tagValueId"
      );
      const tagToShow = [
        ...addedTagValues,
        ...removedTagValues,
        ...tagsWithoutRemoved,
      ];

      return (
        <ValuesList>
          <StyledFixedSizeList
            style={{ display: "block" }}
            key={"list"}
            height={1200}
            width={"100%"}
            itemSize={36}
            overscanCount={5}
            itemCount={tagToShow.length}
            itemData={tagToShow}
          >
            {renderRow}
          </StyledFixedSizeList>
        </ValuesList>
      );
    }

    if (!selectedTag) {
      return (
        <PaperContentCenterDiv id="tag-owners-no-content-div">
          <NoTagsFound
            title="No Tag selected"
            description="Please select a Tag to display it values"
          />
        </PaperContentCenterDiv>
      );
    }

    return (
      <PaperContentCenterDiv id="tag-owners-no-content-div">
        <NoTagsFound title="Nothing has been found" description={tagValuesSearch ? "There are no tag values that meet the specified search query" : "This Tag does not have any values assigned"} />
      </PaperContentCenterDiv>
    );
  };

  return (
    <PaperControl
      id="tags-values-paper-control"
      useHeader={true}
      title={count <= 0 ? "Tag Values" : `Tag Values (${count})`}
      currentSelfHelp={CurrentSelfHelp.TagsValues}
      selfHelpPage={SelfHelpPage.Tags}
      selfHelpSectionName={SectionName.TagsValues}

    >
      <TagValuesDiv id="tag-values-div">
        {<SearchTagValue />}
        {canEditSelectedTag && <AddTagValue />}
        {content()}
      </TagValuesDiv>
    </PaperControl>
  );
}
