import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { remove } from "lodash";
import { TagValue } from "../../models/tags/TagValue";

export interface TagsState {
  addedValues: TagValue[];
  removedValues: TagValue[];
}

const initialState: TagsState = {
  addedValues: [],
  removedValues: [],
};

export const tagsSlice = createSlice({
  name: "tags",
  initialState: initialState,
  reducers: {
    addTagValue(state, action: PayloadAction<TagValue>) {
      if (
        !state.addedValues.some((item) => item.value === action.payload.value)
      ) {
        action.payload.added = true;
        action.payload.removed = undefined;
        state.addedValues = [...state.addedValues, action.payload];
      }
    },
    removeTagValue(state, action: PayloadAction<TagValue>) {
      if (action.payload.tagValueId) {
        action.payload.added = undefined;
        action.payload.removed = true;
        state.removedValues = [...state.removedValues, action.payload];
      } else {
        remove(
          state.addedValues,
          (item) => item.value === action.payload.value
        );
      }
    },
    unRemoveTagValue(state, action: PayloadAction<string>) {
      remove(state.removedValues, (item) => item.value === action.payload);
    },
    clearTagValues(state) {
      state.addedValues = [];
      state.removedValues = [];
    },
  },
});

export const { addTagValue, removeTagValue, unRemoveTagValue, clearTagValues } =
  tagsSlice.actions;

export const selectAddedTags = (state: RootState) => state.tags.addedValues;

export const selectRemovedTags = (state: RootState) => state.tags.removedValues;
export const selectRemovedTagsWithDocuments = (state: RootState) =>
  state.tags.removedValues.filter(
    (tag) => tag.countOfDocuments != undefined && tag.countOfDocuments > 0
  );

export const selectTagsIsEdited = (state: RootState) =>
  state.tags.addedValues.length > 0 || state.tags.removedValues.length > 0;

export default tagsSlice.reducer;
