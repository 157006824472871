import { useTheme } from "styled-components";
import { CustomBookmarkSpecialIcon } from "../../../../../components/tags/TagTypes/TagType/CustomBookmarkSpecialIcon";
import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";
import { SelfHelpIcon } from "../../publishers/SelfHelpIcon";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';

export function TagTypesListStep6() {
    const theme = useTheme();
    return (
        <StepContentDiv>
            <Paragraph>
                There are three Tag Types that can be distinguished by their icon on the list:
                <ul>
                    <li>
                        <SelfHelpIcon icon={<BookmarksOutlinedIcon />} />
                        Multi Value Tag: Allows multiple Tag Values to be added to document from the selected Tag.
                    </li>
                    <li>
                        <SelfHelpIcon icon={<BookmarkBorderOutlinedIcon />} />
                        Single Value Tag: Allows only one Tag Value to be added to document from the selected Tag.
                    </li>
                    <li>
                        <SelfHelpIcon icon={<CustomBookmarkSpecialIcon fontSize={"24px"} fill={theme.palettes.grayscale.dark} />} />
                        Special Tag: All other Tag Types that don&apos;t fall under Single or Multi Value Type are represented by Special Tag icon.
                    </li>
                </ul>
            </Paragraph>
        </StepContentDiv>
    );
}
