import { Paragraph } from "../../../SC/Paragraph";
import { StepContentDiv } from "../../../SC/StepContentDiv";

export function SectionStep1() {
    return (
        <StepContentDiv>
            <Paragraph>
                A list of all categories where the selected Tag can be assigned to a document is displayed in this section.
            </Paragraph>
            <Paragraph>
                The Tag can only be enabled on Category Level 0 and is made available for all categories below.
            </Paragraph>
        </StepContentDiv>
    );
}
