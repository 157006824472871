import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import { ListItemAvatar } from "@mui/material";
import { ItemAvatarDiv } from "../../publishers/publisherCategories/SC/ItemAvatarDiv";
import { StyledAvatar } from "../SC/StyledAvatar";
import { StyledListItem } from "../SC/StyledListItem";
import { StyledListItemText } from "../SC/StyledListItemText";
import { selectSelectedTag } from "../../../app/slices/tagsViewSlice";
import { useAppSelector } from "../../../app/hooks/hooks";
import { TooltipTop } from '../../../controls/Tooltips/ToolipTop';
import { splitCamelCase, TagTypeEnum } from '../../../models/tags/tagsListItem';
import { CustomBookmarkSpecialIcon } from '../TagTypes/TagType/CustomBookmarkSpecialIcon';


export function TagInfo() {
  const selectedTag = useAppSelector(selectSelectedTag);
  if (selectedTag) {
    return (
      <ItemAvatarDiv>
        <StyledListItem>
          <ListItemAvatar sx={{ width: 32, minWidth: 42 }}>
            <StyledAvatar>
              {selectedTag.type === TagTypeEnum.SingleValueTag && <BookmarkBorderOutlinedIcon />}
              {selectedTag.type === TagTypeEnum.MultiValueTag && <BookmarksOutlinedIcon />}
              {selectedTag.type === TagTypeEnum.SpecialTag && <CustomBookmarkSpecialIcon fontSize={"24px"} fill="white" />}
            </StyledAvatar>
          </ListItemAvatar>
          <TooltipTop title={selectedTag.displayName}>
            <StyledListItemText
              primary={selectedTag.displayName}
              secondary={splitCamelCase(selectedTag.type)}
            />
          </TooltipTop>
        </StyledListItem>
      </ItemAvatarDiv>
    );
  }

  return <ItemAvatarDiv />;
}
